import React from "react";

// Mui
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

// App
import PageTemplate, { MaxWidthContainer } from "components/pageTemplate";
import RegisterCard from "components/registerCard";

const styles = (theme) => ({
  sponsorshipsBg: {
    backgroundColor: theme.palette.green.light,
    paddingBottom: "40px",
    marginTop: "-77px",
  },
  contentContainer: {
    flexWrap: "wrap !important",
    [theme.breakpoints.up("md")]: {
      minHeight: "calc(100vh - 400px)",
      flexWrap: "nowrap !important",
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: "calc(100vh - 431px)",
    },
  },

  missionGrid: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginRight: "-30px !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "420px",
      minWidth: "420px",
      marginTop: "-112px !important",
    },
    [theme.breakpoints.up("lg")]: {
      width: "500px",
      minWidth: "500px",
      marginRight: "0px !important",
    },
  },
  missionCard: {
    backgroundColor: `${theme.palette.green.veryLight} !important`,
    marginBottom: "30px",
    padding: "38px 45px !important",
    borderTopRightRadius: "4px !important",
    borderBottomRightRadius: "4px !important",
    [theme.breakpoints.up("md")]: {
      borderTopRightRadius: "0 !important",
      borderBottomRightRadius: "0 !important",
    },
    [theme.breakpoints.up("lg")]: {
      borderTopRightRadius: "4px !important",
      borderBottomRightRadius: "4px !important",
    },
  },
});

const GolferInfo = () => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input type="hidden" name="hosted_button_id" value="M996EAGDXHDJ8" />

    <Grid container spacing={2} style={{ marginTop: "0px" }}>
      <Grid item xs={12} sm={6} lg={3}>
        <input class="input-fill" type="hidden" name="on0" value="First name" />
        <Typography variant="body2">First name *</Typography>
        <input
          class="input-fill"
          type="text"
          id="os0"
          name="os0"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <input class="input-fill" type="hidden" name="on1" value="Last name" />
        <Typography variant="body2">Last name *</Typography>
        <input
          class="input-fill"
          type="text"
          id="os1"
          name="os1"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <input class="input-fill" type="hidden" name="on2" value="Phone" />
        <Typography variant="body2">Phone *</Typography>
        <input
          class="input-fill"
          type="text"
          id="os2"
          name="os2"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <input class="input-fill" type="hidden" name="on3" value="Email" />
        <Typography variant="body2">Email *</Typography>
        <input
          class="input-fill"
          type="text"
          id="os3"
          name="os3"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Typography sx={{ margin: "14px 0px" }}>
          <b>
            If you would like to be grouped with other registering players, add
            their names in the field below.
          </b>
        </Typography>
        <input
          class="input-fill"
          type="hidden"
          name="on4"
          value="Other players"
        />
        <Typography variant="body2">Names of other players in group</Typography>
        <input
          class="input-fill"
          type="text"
          id="os4"
          name="os4"
          maxlength="500"
        />
      </Grid>
    </Grid>

    <div class="addToCartDiv">
      <input
        type="image"
        src="./buttons/addToCart.png"
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const DinnerGuest = () => (
  <form
    target="paypal"
    action="https://www.paypal.com/cgi-bin/webscr"
    method="post"
  >
    <input type="hidden" name="cmd" value="_s-xclick" />
    <input type="hidden" name="hosted_button_id" value="DNVL3HB7AQU8E" />

    <Grid container spacing={2} style={{ marginTop: "0px" }}>
      <Grid item xs={12} sm={6} lg={3}>
        <input class="input-fill" type="hidden" name="on0" value="First name" />
        <Typography variant="body2">First name *</Typography>
        <input
          class="input-fill"
          type="text"
          id="os0"
          name="os0"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <input class="input-fill" type="hidden" name="on1" value="Last name" />
        <Typography variant="body2">Last name *</Typography>
        <input
          class="input-fill"
          type="text"
          id="os1"
          name="os1"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <input class="input-fill" type="hidden" name="on2" value="Phone" />
        <Typography variant="body2">Phone *</Typography>
        <input
          class="input-fill"
          type="text"
          id="os2"
          name="os2"
          maxlength="200"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} lg={3}>
        <input class="input-fill" type="hidden" name="on3" value="Email" />
        <Typography variant="body2">Email *</Typography>
        <input
          class="input-fill"
          type="text"
          id="os3"
          name="os3"
          maxlength="200"
          required
        />
      </Grid>
    </Grid>

    <div class="addToCartDiv">
      <input
        type="image"
        src="./buttons/addToCart.png"
        border="0"
        name="submit"
        alt="PayPal"
      />
    </div>
  </form>
);

const registrationOptions = [
  {
    name: "INDIVIDUAL GOLFER(S)",
    cost: "210",
    description: (
      <>
        If registering
        <b> multiple golfers, </b>fill this form and add to your cart for
        <b> each golfer.</b>
      </>
    ),
    code: <GolferInfo />,
  },
  {
    name: "DINNER GUEST",
    cost: "55",
    description: (
      <>
        Please only use this if the guest is <b>not golfing.</b> Dinner is
        included for golfers. <br />
        If registering multiple dinner guests, fill this form and add to cart
        for <b>each guest.</b>
      </>
    ),
    code: <DinnerGuest />,
  },
];

const Registration = ({ classes }) => {
  return (
    <PageTemplate mainTitle="Registration">
      <Grid className={classes.sponsorshipsBg}>
        <MaxWidthContainer>
          <Grid container spacing={3} className={classes.contentContainer}>
            <Grid item style={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" paragraph>
                    Fill out the forms below and pay on PayPal's website, or{" "}
                    <a
                      href="./Registration.pdf"
                      download="registration"
                      class="link"
                      onClick={() => {
                        console.log("hi");
                      }}
                    >
                      download the form
                    </a>{" "}
                    and mail it to us.
                  </Typography>
                  <Typography variant="body2" paragraph>
                    When checking out, you can pay through PayPal by clicking
                    the yellow "PayPal" button or pay with a credit card by
                    clicking the gray "checkout" button.
                  </Typography>
                </Grid>
                {registrationOptions.map((item) => (
                  <RegisterCard item={item} />
                ))}
              </Grid>
            </Grid>

            <Grid item className={classes.missionGrid}>
              <Card elevation={0} className={classes.missionCard}>
                <Typography
                  align="center"
                  paragraph
                  style={{ fontWeight: 700 }}
                >
                  2024 PREMIER & MASTER SPONSORS
                </Typography>
                <Grid
                  container
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  paddingTop="30px"
                  paddingBottom="30px"
                >
                  <img
                    src="./sponsors/master/Hatfield.png"
                    alt="Hatfield Meats"
                    style={{
                      width: "280px",
                      margin: "10px 0px 20px",
                    }}
                  />
                  <img
                    src="./sponsors/master/PrestoTape.png"
                    alt="Presto Tape logo"
                    style={{
                      width: "220px",
                    }}
                  />
                  <img
                    src="./sponsors/master/Unique.png"
                    alt="Unique Wealth logo"
                    style={{
                      width: "220px",
                      margin: "20px 0px",
                    }}
                  />
                  <img
                    src="./sponsors/master/IHS.png"
                    alt="Industry Health Solutions"
                    style={{
                      width: "230px",
                      margin: "20px 0px",
                    }}
                  />
                  <img
                    src="./sponsors/master/Alderfer.png"
                    alt="Alderfer Glass"
                    style={{
                      width: "240px",
                      margin: "0px 0px 10px",
                    }}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </MaxWidthContainer>
      </Grid>
    </PageTemplate>
  );
};

export default withStyles(styles)(Registration);
